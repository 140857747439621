// import firebase from '../firebase'
// import "firebase/compat/firestore"
// const db = firebase.firestore()
const CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      passphrase: "prepvnchat",
    };
  },
  methods: {
    signInWithInfoUser() {
      //todo make user prep web
    },
    saveUserToLocalStorage(user) {
      if (!process.server) {
        const encryptWithAES = CryptoJS.AES.encrypt(
          JSON.stringify(user),
          this.passphrase
        ).toString();
        localStorage.setItem("enus", encryptWithAES);
      }
    },
    saveUserToStore(user) {
      this.$store.commit("prepChatUser/add", user);
    },
    decryptUser() {
      //RUN NUXT JS
      if (!process.server) {
        const encryptedUser = localStorage.getItem("enus");
        if (encryptedUser) {
          const bytes = CryptoJS.AES.decrypt(encryptedUser, this.passphrase);
          const decryptWithAES = bytes.toString(CryptoJS.enc.Utf8);
          return JSON.parse(decryptWithAES);
        }
      }
    },
  },
};
