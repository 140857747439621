import firebase from "../firebase";
import "firebase/compat/firestore";

const db = firebase.firestore();

export default {
  data() {
    return {
      groups: [],
      currentGroup: undefined,
    };
  },
  methods: {
    createGroup(newGroup) {
      return new Promise((resolve, reject) => {
        db.collection("groups")
          .add(newGroup)
          .then(function (docRef) {
            newGroup.id = docRef.id;
            resolve(newGroup);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    filterGroup(userArray) {
      return new Promise((resolve, reject) => {
        let groupRef = db.collection("groups");

        const userArr = [];
        userArray.forEach((userId) => {
          userArr.push(userId);
        });

        //Note: using in or array-contains-any
        userArr.forEach((val) => {
          let textKey = `members.${val}`;
          groupRef = groupRef.where(textKey, "==", true);
        });
        groupRef = groupRef.where("type", "==", "private");
        groupRef
          .get()
          .then(function (querySnapshot) {
            const allGroups = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              data.id = doc.id;
              allGroups.push(data);
            });
            if (allGroups.length > 0) {
              resolve(allGroups[0]);
            } else {
              resolve(null);
            }
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    sortGroup(array) {
      //group.createdAt.seconds
      //group.recentMessage.sentAt.seconds
      const size = array.length;
      for (let i = 0; i < size - 1; i++) {
        // swapped keeps track of swapping
        let swapped = Boolean(true);
        for (let j = 0; j < size - i - 1; j++) {
          // To sort in descending order, change > to < in this line.
          //custom condition
          let varSortOne = null;
          if (array[j].recentMessage) {
            varSortOne = array[j].recentMessage.sentAt.seconds;
          } else {
            varSortOne = array[j].createdAt.seconds;
          }

          let varSortTwo = null;
          if (array[j + 1].recentMessage) {
            varSortTwo = array[j + 1].recentMessage.sentAt.seconds;
          } else {
            varSortTwo = array[j + 1].createdAt.seconds;
          }

          if (varSortOne < varSortTwo) {
            // swap if greater is at the rear position
            let temp = array[j];
            array[j] = array[j + 1];
            array[j + 1] = temp;
            swapped = false;
          }
        }

        if (swapped === true) break;
      }

      return array;
    },
    async fetchGroupByUserID(uid) {
      const vm = this;
      return new Promise(() => {
        let groupRef = db.collection("groups");

        let textKey = `members.${uid}`;
        groupRef = groupRef.where(textKey, "==", true);

        // groupRef
        //     .where('members', 'array-contains', uid)

        groupRef.onSnapshot((querySnapshot) => {
          const allGroups = [];
          const openGroups = [];
          const closeGroups = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            if (data.type == "customer_service") {
              if (data.status == "Open") {
                openGroups.push(data);
              } else {
                closeGroups.push(data);
              }
            } else {
              if (data.recentMessage) {
                allGroups.push(data);
              }
            }
          });
          vm.$store.commit("prepChatGroup/add", vm.sortGroup(allGroups));
          vm.$store.commit("prepChatGroup/addCs", vm.sortGroup(openGroups));
          vm.$store.commit(
            "prepChatGroup/addCloseGroupCs",
            vm.sortGroup(closeGroups)
          );
        });
      });
    },
    async fetchGroupByManager() {
      const vm = this;
      return new Promise(() => {
        let groupRef = db.collection("groups");

        groupRef.onSnapshot((querySnapshot) => {
          const allGroups = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            if (data.recentMessage) {
              allGroups.push(data);
            }
          });
          // vm.groups = vm.sortGroup(allGroups)
          vm.$store.commit("prepChatGroup/addgroupManager", allGroups);
        });
      });
    },
    async fetchGroupByManagerCS() {
      const vm = this;
      return new Promise(() => {
        let groupRef = db.collection("groups");

        groupRef.onSnapshot((querySnapshot) => {
          const allGroups = [];
          const allGroupsClose = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            if (data.type == "customer_service") {
              if (data.status == "Open") {
                allGroups.push(data);
              } else {
                allGroupsClose.push(data);
              }
            }
          });
          // vm.groups = vm.sortGroup(allGroups)
          vm.$store.commit(
            "prepChatGroup/addgroupManagerCs",
            vm.sortGroup(allGroups)
          );
          vm.$store.commit(
            "prepChatGroup/addgroupManagerCsClose",
            allGroupsClose
          );
        });
      });
    },
    fetchGroupByIds(groupIds) {
      const groups = [];
      const groupRef = db.collection("groups");
      groupIds.forEach(async (groupId) => {
        await groupRef
          .doc(groupId)
          .get()
          .then(function (doc) {
            groups.push(doc.data());
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.error("Error get document: ", error);
          });
      });
      this.groups = groups;
    },
    updateGroup(group) {
      if (group.photoURL == undefined) {
        group.photoURL = null;
      }

      db.collection("groups")
        .doc(group.id)
        .set(group)
        .then(function () {})
        .catch(function (error) {
          // eslint-disable-next-line no-console
          console.error("Error writing document: ", error);
        });
    },
    addNewGroupToUser(user, groupId) {
      const groups = user.groups ? user.groups : [];
      const existed = groups.filter((group) => group === groupId);
      if (existed.length === 0) {
        groups.push(groupId);
        user.groups = groups;
        const userRef = db.collection("users");
        userRef.doc(String(user.uid)).set(user);
      }
    },
    findGroupCS(uid) {
      return new Promise((resolve, reject) => {
        let textKey = `members.${uid}`;

        db.collection("groups")
          .where(textKey, "==", true)
          .where("type", "==", "customer_service")
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size == 0) {
              resolve(null);
            }
            querySnapshot.forEach((doc) => {
              if (doc.id) {
                resolve(doc.data());
              }
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
