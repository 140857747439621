import firebase from "../firebase";
import "firebase/compat/firestore";
const db = firebase.firestore();
export default {
  data() {
    return {
      // messages: [],
    };
  },
  methods: {
    saveMessage(messageText, sentAt, currentGroupId, type = "text") {
      let vmessage = null;
      if (type == "text") {
        if (messageText.trim()) {
          vmessage = {
            messageText,
            sentAt,
            sentBy: this.user.uid,
            type: type,
          };
        }
      }

      if (type == "image") {
        vmessage = {
          messageText,
          sentAt,
          sentBy: this.user.uid,
          type: type,
        };
      }

      if (type == "tag-marking") {
        vmessage = {
          messageText,
          sentAt,
          sentBy: this.user.uid,
          type: type,
        };
      }

      if (vmessage) {
        return new Promise((resolve, reject) => {
          db.collection("messages")
            .doc(String(currentGroupId))
            .collection("messages")
            .add(vmessage)
            .then(function () {
              //docRef
              // vmessage.id = docRef.id
              resolve(vmessage);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      }
    },
    // fetchMessagesByGroupId(groupId) {
    //     const vm = this
    //     vm.messages = []
    //     db.collection('messages')
    //         .doc(groupId.trim())
    //         .collection('messages')
    //         .orderBy('sentAt')
    //         .onSnapshot((querySnapshot) => {
    //             const allMessages = []
    //             querySnapshot.forEach((doc) => {
    //                 if (doc) allMessages.push(doc.data())
    //             })
    //             vm.messages = allMessages
    //         })
    // }
  },
};
